import React, { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import logo from "./../../images/BlackLogo.png";
import { Row } from "../../../node_modules/react-bootstrap";
import { AppContext } from "../AppLayout";

export default function SideMenu(props: any) {
  const context = useContext(AppContext);
  return (
    <Fragment>
      <Row className="d-none d-sm-none d-md-none d-lg-block d-xl-block">
        <Link to="/">
          <img alt="logo" className="logo" src={logo} />
        </Link>
      </Row>
      <div className={"main-menu-title"} style={{ marginTop: 0 }}>
        Architecture
      </div>

      <Link className={"menu-item"} to="/competitions">
        Competitions
      </Link>

      <Link className={"menu-item"} to="/healthcare">
        Medical & Healthcare
      </Link>

      <Link className={"menu-item"} to="/hospitality">
        Hotels & Hospitality
      </Link>

      <Link className={"menu-item"} to="/houses">
        Houses
      </Link>

      <Link className={"menu-item"} to="/commercial">
        Offices & Commercial
      </Link>

      <Link className={"menu-item"} to="/residential">
        Residential Buildings
      </Link>

      <Link
        className={"main-menu-item"}
        to="/projects?name=interior-design"
        onClick={() => {
          context.setCurrentPageImgCount(34);
          context.setCurrentPageCategory("Rendering & Visualization");
          context.setCurrentPageTitle("INTERIOR DESIGN");
        }}
      >
        Interior Design
      </Link>

      <div className={"main-menu-title"} style={{ marginTop: 0 }}>
        Rendering & Visualization
      </div>

      <Link
        className={"menu-item"}
        to="/projects?name=render-exterior"
        onClick={() => {
          context.setCurrentPageImgCount(60);
          context.setCurrentPageCategory("Rendering & Visualization");
          context.setCurrentPageTitle("EXTERIOR");
        }}
      >
        Exterior
      </Link>

      <Link
        className={"menu-item"}
        to="/projects?name=render-interior"
        onClick={() => {
          context.setCurrentPageImgCount(14);
          context.setCurrentPageTitle("INTERIOR");
        }}
      >
        Interior
      </Link>

      <Link className={"menu-item"} to="/video">
        Video
      </Link>

      <Link className={"menu-item"} to="/vr">
        VR / 360
      </Link>

      <Link className={"main-menu-item"} to="/contact">
        Contact
      </Link>
    </Fragment>
  );
}
