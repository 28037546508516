import React, { Fragment, useContext, useEffect } from "react";
import "./index.scss";
import ProjectsComponent from "../../components/ProjectsComponent";
import { AppContext } from "../../components/AppLayout";
import { Col, Row } from "react-bootstrap";
export default function Residential(props: any) {
  const images = [
    {
      src: "/../../images/residential/1DECORESIDENCEMAMAIA/1.jpg",
      width: 1.5,
      height: 1,
      key: "1",
      project: "residential/1DECORESIDENCEMAMAIA",
      projectName: "DECO RESIDENCE - MAMAIA",
      totalPics: 2,
    },
    {
      src: "/../../images/residential/2SP5ERESIDENCEMAMAIA/1.jpg",
      width: 1.5,
      height: 1,
      key: "2",
      project: "residential/2SP5ERESIDENCEMAMAIA",
      projectName: "SP5E RESIDENCE - MAMAIA",
      totalPics: 2,
    },
    {
      src: "/../../images/residential/3NORDISHIGHRESIDENCEBUCURESTI/1.jpg",
      width: 1.5,
      height: 1,
      key: "3",
      project: "residential/3NORDISHIGHRESIDENCEBUCURESTI",
      projectName: "NORDIS HIGH RESIDENCE  - BUCURESTI",
      totalPics: 5,
    },
    {
      src: "/../../images/residential/4TUDORSRESIDENCEMAMAIA/1.jpg",
      width: 1.5,
      height: 1,
      key: "4",
      project: "residential/4TUDORSRESIDENCEMAMAIA",
      projectName: "TUDORS RESIDENCE - MAMAIA",
      totalPics: 6,
    },
    {
      src: "/../../images/residential/5DP4ERESIDENCEBUCURESTI/1.jpg",
      width: 1.5,
      height: 1,
      key: "5",
      project: "residential/5DP4ERESIDENCEBUCURESTI",
      projectName: "DP4E RESIDENCE - BUCURESTI",
      totalPics: 1,
    },
    {
      src: "/../../images/residential/6P5ERESIDENCEMAMAIA/1.jpg",
      width: 1.5,
      height: 1,
      key: "6",
      project: "residential/6P5ERESIDENCEMAMAIA",
      projectName: "P5E RESIDENCE - MAMAIA",
      totalPics: 1,
    },
    {
      src: "/../../images/residential/7ANMRESIDENCENAVODARI/1.jpg",
      width: 1.5,
      height: 1,
      key: "7",
      project: "residential/7ANMRESIDENCENAVODARI",
      projectName: "ANM RESIDENCE - NAVODARI",
      totalPics: 4,
    },
    {
      src: "/../../images/residential/8P3ERESIDENCENAVODARI/1.jpg",
      width: 1.5,
      height: 1,
      key: "8",
      project: "residential/8P3ERESIDENCENAVODARI",
      projectName: "P3E RESIDENCE - NAVODARI",
      totalPics: 1,
    },
    {
      src: "/../../images/residential/9DP6ERESIDENCECONSTANTA/1.jpg",
      width: 1.5,
      height: 1,
      key: "9",
      project: "residential/9DP6ERESIDENCECONSTANTA",
      projectName: "DP6E RESIDENCE - CONSTANTA",
      totalPics: 2,
    },
    {
      src: "/../../images/residential/10SP8ERESIDENCEBUCURESTI/1.jpg",
      width: 1.5,
      height: 1,
      key: "10",
      project: "residential/10SP8ERESIDENCEBUCURESTI",
      projectName: "SP8E RESIDENCE - BUCURESTI",
      totalPics: 1,
    },
    {
      src: "/../../images/residential/11SP10ERESIDENCEMAMAIA/1.jpg",
      width: 1.5,
      height: 1,
      key: "11",
      project: "residential/11SP10ERESIDENCEMAMAIA",
      projectName: "SP10E RESIDENCE - MAMAIA",
      totalPics: 1,
    },
    {
      src: "/../../images/residential/12SP10ERESIDENCEBUCURESTI/1.jpg",
      width: 1.5,
      height: 1,
      key: "12",
      project: "residential/12SP10ERESIDENCEBUCURESTI",
      projectName: "SP10E RESIDENCE - BUCURESTI",
      totalPics: 2,
    },
    {
      src: "/../../images/residential/13SOLID507RESIDENCEMAMAIA/1.jpg",
      width: 1.5,
      height: 1,
      key: "13",
      project: "residential/13SOLID507RESIDENCEMAMAIA",
      projectName: "SOLID 507 RESIDENCE MAMAIA",
      totalPics: 6,
    },
    {
      src: "/../../images/residential/14SOLIDRESIDENCEMAMAIA/1.jpg",
      width: 1.5,
      height: 1,
      key: "14",
      project: "residential/14SOLIDRESIDENCEMAMAIA",
      projectName: "SOLID RESIDENCE - MAMAIA",
      totalPics: 3,
    },
    {
      src: "/../../images/residential/15PAPAPRTMENTSCONSTANTA/1.jpg",
      width: 1.5,
      height: 1,
      key: "15",
      project: "residential/15PAPAPRTMENTSCONSTANTA",
      projectName: "PAP APRTMENTS - CONSTANTA",
      totalPics: 2,
    },
  ];
  const context = useContext(AppContext);
  useEffect(() => {
    context.setCurrentPageCategory("Residential Buildings");
  }, []);
  return (
    <Fragment>
      <div className="main-content">
        <Col>
          <Row>
            <div
              style={{
                marginBottom: 30,
                marginLeft: 10,
                fontFamily: "Roboto-Light",
                color: "#757575",
                fontSize: "14px",
              }}
              className={"title"}
            >
              Architecture
            </div>
          </Row>
          <Row>
            <div
              style={{
                marginLeft: 10,
                marginBottom: 50,
                fontFamily: "Wremena Regular",
                fontSize: "20px",
              }}
            >
              RESIDENTIAL BUILDINGS
            </div>
          </Row>
        </Col>
        <ProjectsComponent images={images} />
      </div>
    </Fragment>
  );
}
