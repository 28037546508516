import React, { Fragment, useEffect, useContext } from "react";
import "./index.scss";
import ProjectsComponent from "../../components/ProjectsComponent";
import { AppContext } from "../../components/AppLayout";
import { Col, Row } from "react-bootstrap";
export default function Healthcare(props: any) {
  const images = [
    {
      src: "/../../images/healthcare/1CERMED1/1.jpg",
      width: 1.5,
      height: 1,
      key: "1",
      project: "healthcare/1CERMED1",
      projectName: "CERMED MEDICAL CENTER 1 - CONSTANTA",
      totalPics: 2,
    },
    {
      src: "/../../images/healthcare/2CERMED2/1.jpg",
      width: 1.5,
      height: 1,
      key: "2",
      project: "healthcare/2CERMED2",
      projectName: "CERMED MEDICAL CENTER 2 - CONSTANTA",
      totalPics: 2,
    },
    {
      src: "/../../images/healthcare/3RADIOTHERAPYPLOIESTI/1.jpg",
      width: 1.5,
      height: 1,
      key: "3",
      project: "healthcare/3RADIOTHERAPYPLOIESTI",
      projectName: "RADIOTHERAPY CENTRE - PLOIESTI",
      totalPics: 3,
    },
  ];
  const context = useContext(AppContext);
  useEffect(() => {
    context.setCurrentPageCategory("Medical & Healthcare");
  }, []);
  return (
    <Fragment>
      <div className="main-content">
        <Col>
          <Row>
            <div
              style={{
                marginBottom: 30,
                marginLeft: 10,
                fontFamily: "Roboto-Light",
                color: "#757575",
                fontSize: "14px",
              }}
              className={"title"}
            >
              Architecture
            </div>
          </Row>
          <Row>
            <div
              style={{
                marginLeft: 10,
                marginBottom: 50,
                fontFamily: "Wremena Regular",
                fontSize: "20px",
              }}
            >
              MEDICAL & HEALTHCARE
            </div>
          </Row>
        </Col>
        <ProjectsComponent images={images} />
      </div>
    </Fragment>
  );
}
