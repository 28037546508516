import React, {
  Fragment,
  useState,
  createContext,
  useEffect,
  useRef,
} from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Contact from "../../scenes/Contact";
import "./index.scss";
import SideMenu from "../SideMenu";
import Footer from "../Footer";
import Competitions from "../../scenes/Competitions";
import Home from "../../scenes/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Overlay } from "react-bootstrap";
import ProjectsPage from "../../scenes/ProjectsPage";
import Healthcare from "../../scenes/Healthcare";
import Hospitality from "../../scenes/Hospitality";
import Houses from "../../scenes/Houses";
import Commercial from "../../scenes/Commercial";
import Residential from "../../scenes/Residential";
import { HamburgerButton } from "react-hamburger-button";
import logo from "./../../images/BlackLogo.png";
import GalleryComponent from "../GalleryComponent";
import Video from "../../scenes/Video";
import VrComponent from "../../scenes/VR";

export enum supportedLanguages {
  ro,
  en,
}

export const AppContext = createContext({
  language: supportedLanguages.ro,
  setLanguage: (lng: supportedLanguages) => {},
  currentPageImgCount: 0,
  setCurrentPageImgCount: (count: number) => {},
  currentPageCategory: "",
  setCurrentPageCategory: (category: string) => {},
  currentPageTitle: "",
  setCurrentPageTitle: (title: string) => {},
});

export default function AppLayout(props: any) {
  const [language, setLanguage] = useState(supportedLanguages.ro);
  const [currentPageImgCount, setCurrentPageImgCount] = useState(0);
  const [currentPageTitle, setCurrentPageTitle] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentPageCategory, setCurrentPageCategory] = useState("");

  const sideMenuRef = useRef(null);

  useEffect(() => {
    const handleClick = (event: any) => {
      if (sideMenuRef.current && !sideMenuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClick);
    document.addEventListener("contextmenu", (e: any) => {
      e.preventDefault();
    });
    localStorage.setItem("language", language.toString());

    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("contextmenu", (e: any) => {
        e.preventDefault();
      });
    };
  }, [sideMenuRef, language]);

  return (
    <Router>
      <Fragment>
        <AppContext.Provider
          value={{
            language: language,
            setLanguage: (lng: supportedLanguages) => {
              setLanguage(lng);
            },
            currentPageImgCount: currentPageImgCount,
            currentPageCategory: currentPageCategory,
            setCurrentPageCategory: (category: string) =>
              setCurrentPageCategory(category),
            setCurrentPageImgCount: (count: number) =>
              setCurrentPageImgCount(count),
            currentPageTitle: currentPageTitle,
            setCurrentPageTitle: (title: string) => setCurrentPageTitle(title),
          }}
        >
          <Container fluid>
            <Row className="d-xl-none d-lg-none d-md-block d-sm-block d-block">
              <Container fluid>
                <Row>
                  <Col xs={4} sm={4} md={4}>
                    <Link to="/">
                      <img alt="logo" className="img-fluid" src={logo} />
                    </Link>
                  </Col>

                  <Col
                    xs={{ offset: 6, span: 2 }}
                    sm={{ offset: 6, span: 2 }}
                    md={{ offset: 6, span: 2 }}
                    style={{ marginTop: "13px", zIndex: 999 }}
                  >
                    <div className="burgerBtn">
                      <HamburgerButton
                        open={menuOpen}
                        onClick={() => setMenuOpen(!menuOpen)}
                        width={18}
                        height={15}
                        strokeWidth={1}
                        color="black"
                        animationDuration={0.5}
                      ></HamburgerButton>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Row>
            <Col xs={1} sm={1} md={1} className="fixed-top">
              <Overlay ref={sideMenuRef} show={menuOpen} placement="top-end">
                <div
                  className="d-flex flex-column right-menu"
                  style={{
                    height: "90vh",
                    marginRight: "5%",
                    paddingTop: "3vh",
                    paddingLeft: 20,
                    background: "#FFFFFFFF",
                  }}
                >
                  <SideMenu />
                </div>
              </Overlay>
            </Col>
            <Row>
              <Col
                className="d-none d-sm-none d-md-none d-lg-block d-xl-block"
                style={{ marginLeft: "6%", position: "fixed" }}
                xl={2}
                lg={2}
              >
                <div
                  className="d-flex flex-column "
                  style={{ marginTop: "5vh" }}
                >
                  <SideMenu />
                </div>
              </Col>

              <Col
                xl={{ span: 7, offset: 3 }}
                lg={{ span: 8, offset: 2 }}
                xs={10}
                sm={10}
              >
                <Row style={{ marginBottom: "10%", marginLeft: "15%" }}>
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/projects" component={ProjectsPage} />
                    <Route exact path="/contact" component={Contact} />
                    <Route
                      exact
                      path="/competitions"
                      component={Competitions}
                    />
                    <Route exact path="/healthcare" component={Healthcare} />
                    <Route exact path="/hospitality" component={Hospitality} />
                    <Route exact path="/houses" component={Houses} />
                    <Route exact path="/commercial" component={Commercial} />
                    <Route exact path="/residential" component={Residential} />
                    <Route exact path="/video" component={Video} />
                    <Route exact path="/vr" component={VrComponent} />
                  </Switch>
                </Row>
                <Footer
                  style={{ marginLeft: "500px" }}
                  className={"main-content-home"}
                />
              </Col>
              <Col xl={{ span: 1, offset: 1 }} lg={1} md={1} sm={1} xs={1}>
                <div
                  style={{
                    position: "fixed",
                    marginTop: "40vh",
                    textAlign: "right",
                    writingMode: "vertical-lr",
                    paddingLeft: "75px",
                  }}
                >
                  <span
                    style={{
                      marginBottom: "40px",
                      fontFamily: "Roboto-Light",
                      fontSize: "16px",
                      textDecoration: "underline",
                    }}
                  >
                    <a
                      href="http://www.facebook.com/UpStructRomania"
                      style={{ color: "#757575" }}
                    >
                      Facebook
                    </a>
                  </span>
                  <span
                    style={{
                      marginBottom: "80px",
                      fontFamily: "Roboto-Light",
                      fontSize: "16px",
                      textDecoration: "underline",
                    }}
                  >
                    <a
                      href="http://www.instagram.com/up.struct.studio/"
                      style={{ color: "#757575" }}
                    >
                      Instagram
                    </a>
                  </span>
                </div>
              </Col>
            </Row>
          </Container>
        </AppContext.Provider>
      </Fragment>
    </Router>
  );
}
