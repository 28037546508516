import React, { Fragment, useContext, useEffect } from "react";
import ProjectsComponent from "../../components/ProjectsComponent";
import { AppContext } from "../../components/AppLayout";
import { Row, Col } from "react-bootstrap";
export default function Competitions(props: any) {
  const images = [
    {
      src: "/../../images/competitions/1CARSHOWROOM/1.jpg",
      width: 1.5,
      height: 1,
      key: "1",
      project: "competitions/1CARSHOWROOM",
      projectCategory: "Architecture",
      projectName: "OFFICE TOWER & CAR SHOWROOM -  BUCURESTI",
      totalPics: 8,
    },
    {
      src: "/../../images/competitions/2SPORTCENTER/1.jpg",
      width: 1.5,
      height: 1,
      key: "2",
      project: "competitions/2SPORTCENTER",
      projectCategory: "Architecture",
      projectName: "SPORT CENTER CONCEPT - CONSTANTA",
      totalPics: 10,
    },
    {
      src: "/../../images/competitions/3REVOLUTIONSQUARE/1.jpg",
      width: 1.5,
      height: 1,
      key: "3",
      project: "competitions/3REVOLUTIONSQUARE",
      projectCategory: "Architecture",
      projectName: "REVOLUTION SQUARE  - BUCURESTI",
      totalPics: 5,
    },
    {
      src: "/../../images/competitions/4PUBLICSPACE/1.jpg",
      width: 1.5,
      height: 1,
      key: "4",
      project: "competitions/4PUBLICSPACE",
      projectCategory: "Architecture",
      projectName: "PUBLIC SPACE - BUCURESTI",
      totalPics: 7,
    },
  ];
  const context = useContext(AppContext);
  useEffect(() => {
    context.setCurrentPageCategory("Competitions");
  }, []);
  return (
    <Fragment>
      <div className="main-content">
        <Col>
          <Row>
            <div
              style={{
                marginBottom: 30,
                marginLeft: 10,
                fontFamily: "Roboto-Light",
                color: "#757575",
                fontSize: "14px",
              }}
              className={"title"}
            >
              Architecture
            </div>
          </Row>
          <Row>
            <div
              style={{
                marginLeft: 10,
                marginBottom: 50,
                fontFamily: "Wremena Regular",
                fontSize: "20px",
              }}
            >
              COMPETITIONS
            </div>
          </Row>
        </Col>
        <ProjectsComponent images={images} />
      </div>
    </Fragment>
  );
}
