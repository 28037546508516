import React, { Fragment, useEffect, useContext } from "react";
import "./index.scss";
import ProjectsComponent from "../../components/ProjectsComponent";
import { AppContext } from "../../components/AppLayout";
import { Row, Col } from "react-bootstrap";
export default function Commercial(props: any) {
  const images = [
    {
      src: "/../../images/commercial/1BOEMATOWERCONSTANTA/1.jpg",
      width: 1.5,
      height: 1,
      key: "1",
      project: "commercial/1BOEMATOWERCONSTANTA",
      projectName: "BOEMA TOWER - CONSTANTA",
      totalPics: 2,
    },
    {
      src: "/../../images/commercial/4COMMERCIALSPACESBUCURESTI/1.jpg",
      width: 1.5,
      height: 1,
      key: "4",
      project: "commercial/4COMMERCIALSPACESBUCURESTI",
      projectName: "COMMERCIAL SPACES - BUCURESTI",
      totalPics: 3,
    },
    {
      src: "/../../images/commercial/2FRANKESHOWROOM/1.jpg",
      width: 1.5,
      height: 1,
      key: "2",
      project: "commercial/2FRANKESHOWROOM",
      projectName: "FRANKE SHOWROOM",
      totalPics: 6,
    },
  ];
  const context = useContext(AppContext);
  useEffect(() => {
    context.setCurrentPageCategory("Offices & Commercial");
  }, []);
  return (
    <Fragment>
      <div className="main-content">
        <Col>
          <Row>
            <div
              style={{
                marginBottom: 30,
                marginLeft: 10,
                fontFamily: "Roboto-Light",
                color: "#757575",
                fontSize: "14px",
              }}
              className={"title"}
            >
              Architecture
            </div>
          </Row>
          <Row>
            <div
              style={{
                marginLeft: 10,
                marginBottom: 50,
                fontFamily: "Wremena Regular",
                fontSize: "20px",
              }}
            >
              OFFICES & COMMERCIAL
            </div>
          </Row>
        </Col>
        <ProjectsComponent images={images} />
      </div>
    </Fragment>
  );
}
