import React, { Fragment, useContext, useEffect } from "react";
import "./index.scss";
import ProjectsComponent from "../../components/ProjectsComponent";
import { AppContext } from "../../components/AppLayout";
import { Col, Row } from "react-bootstrap";
export default function Hospitality(props: any) {
  const images = [
    {
      src: "/../../images/hospitality/1BRANDHOTELNAVODARI/1.jpg",
      width: 1.5,
      height: 1,
      key: "1",
      project: "hospitality/1BRANDHOTELNAVODARI",
      projectName: "BRAND HOTEL NAVODARI",
      totalPics: 3,
    },
    {
      src: "/../../images/hospitality/2TOURISTAPRTMENTSNAVODARI/1.jpg",
      width: 1.5,
      height: 1,
      key: "2",
      project: "hospitality/2TOURISTAPRTMENTSNAVODARI",
      projectName: "TOURIST APRTMENTS - NAVODARI",
      totalPics: 3,
    },
    {
      src: "/../../images/hospitality/3POIANAMARULUIHOTELBRASOV/1.jpg",
      width: 1.5,
      height: 1,
      key: "3",
      project: "hospitality/3POIANAMARULUIHOTELBRASOV",
      projectName: "POIANA MARULUI HOTEL - BRASOV",
      totalPics: 1,
    },
    {
      src: "/../../images/hospitality/4HOTELBRASOV/1.jpg",
      width: 1.5,
      height: 1,
      key: "4",
      project: "hospitality/4HOTELBRASOV",
      projectName: "HOTEL - BRASOV",
      totalPics: 3,
    },
    {
      src: "/../../images/hospitality/5NEVADAHOTELMAMAIA/1.jpg",
      width: 1.5,
      height: 1,
      key: "5",
      project: "hospitality/5NEVADAHOTELMAMAIA",
      projectName: "NEVADA HOTEL - MAMAIA",
      totalPics: 4,
    },
    {
      src: "/../../images/hospitality/6T2HOTELMAMAIA/1.jpg",
      width: 1.5,
      height: 1,
      key: "6",
      project: "hospitality/6T2HOTELMAMAIA",
      projectName: "T2 HOTEL - MAMAIA",
      totalPics: 5,
    },
    {
      src: "/../../images/hospitality/7TOURISTAPARTMENTSOLIMP/1.jpg",
      width: 1.5,
      height: 1,
      key: "7",
      project: "hospitality/7TOURISTAPARTMENTSOLIMP",
      projectName: "TOURIST APARTMENTS - OLIMP",
      totalPics: 4,
    },
    {
      src: "/../../images/hospitality/8TOURISTAPARTMENTSVAMAVECHE/1.jpg",
      width: 1.5,
      height: 1,
      key: "8",
      project: "hospitality/8TOURISTAPARTMENTSVAMAVECHE",
      projectName: "TOURIST APARTMENTS - VAMA VECHE",
      totalPics: 9,
    },
    {
      src: "/../../images/hospitality/9CUZAVODAHOTELCONSTANTA/1.jpg",
      width: 1.5,
      height: 1,
      key: "9",
      project: "hospitality/9CUZAVODAHOTELCONSTANTA",
      projectName: "CUZA VODA HOTEL - CONSTANTA",
      totalPics: 7,
    },
    {
      src: "/../../images/hospitality/10PAMBEACHOLIMP/1.jpg",
      width: 1.5,
      height: 1,
      key: "10",
      project: "hospitality/10PAMBEACHOLIMP",
      projectName: "PAM BEACH OLIMP",
      totalPics: 10,
    },
  ];
  const context = useContext(AppContext);
  useEffect(() => {
    context.setCurrentPageCategory("Hotels & Hospitality");
  }, []);
  return (
    <Fragment>
      <div className="main-content">
        <Col>
          <Row>
            <div
              style={{
                marginBottom: 30,
                marginLeft: 10,
                fontFamily: "Roboto-Light",
                color: "#757575",
                fontSize: "14px",
              }}
              className={"title"}
            >
              Architecture
            </div>
          </Row>
          <Row>
            <div
              style={{
                marginLeft: 10,
                marginBottom: 50,
                fontFamily: "Wremena Regular",
                fontSize: "20px",
              }}
            >
              HOTELS & HOSPITALITY
            </div>
          </Row>
        </Col>
        <ProjectsComponent images={images} />
      </div>
    </Fragment>
  );
}
