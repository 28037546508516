const Images: any =
    [
        {
            src: "../../images/home-gallery/HOMEPAGE - COPERTA 1.jpg",
            width: 1.5,
            height: 1,
            key: "1",
            project: ""
        },
        {
            src: "../../images/home-gallery/HOMEPAGE - COPERTA 2.jpg",
            width: 1.5,
            height: 1,
            key: "2",
            project: ""
        },
        {
            src: "../../images/home-gallery/HOMEPAGE - COPERTA 3.jpg",
            width: 1.5,
            height: 1,
            key: "3",
            project: ""
        },
        {
            src: "../../images/home-gallery/HOMEPAGE - COPERTA 4.jpg",
            width: 1.5,
            height: 1,
            key: "4",
            project: ""
        },
        {
            src: "../../images/home-gallery/HOMEPAGE - COPERTA 5.jpg",
            width: 1.5,
            height: 1,
            key: "5",
            project: ""
        },
    ]

export default Images;