import React, { Fragment, useContext } from "react";
import "./index.scss";
import { Row, Col } from "react-bootstrap";
import { supportedLanguages, AppContext } from "../../components/AppLayout";

export default function Contact(props: any) {
  const appContext = useContext(AppContext);
  return (
    <Fragment>
      <Row className={"main-content"} style={{ justifyContent: "center" }}>
        <Col xl={7} style={{ minHeight: "85vh" }}>
          <div style={{ marginBottom: 30 }} className={"title"}>
            Contact
          </div>

          <p style={{ marginBottom: 50 }}>
            Up Struct Studio este o firmă de arhitectură cu servicii complete.
            Proiectăm cu cu gândul la viitor, de aceea realizam modele adaptive
            și durabile. Tehnologia se mișcă rapid și la fel ar trebui să fie și
            construcțiile. Combinăm toate activitățile pentru a susține
            proiectele tale, indiferent ca ai nevoie de o experiență inovatoare
            în VR a clădirii sau de o animație video. Suntem specializați în
            arhitectură, design interior și planificare urbană. Serviciile
            noastre includ, de asemenea, soluții de randare și vizualizare:
            randări exterioare / interioare, modelare 3D, animații video ,
            randări 360 și experiențe VR.
          </p>

          <p style={{ marginBottom: 70 }}>
            Up Struct Studio is a full-service architecture firm. We design with
            the future in mind, that is why we create designs that are adaptive
            as well as resilient. Technology moves fast and so should
            construction. We combine all the activities to support your project,
            whether you require an innovative VR experience of your building or
            an animation video. We specialize in architecture, interior design
            and urban development. Our services also include rendering &
            visualization solutions: exterior/interior renders, 3D modelling,
            animated videos, 360 videos and VR experiences.
          </p>

          <div className={"title"} style={{ color: "#000000" }}>
            Str. Mircea cel Batran, nr 23 <br />
            900658 Constanta <br />
            Romania <br />
            <br />
            {/* Str Zborului nr. 1 <br />
            030594 Bucuresti <br />
            Romania <br />
            <br /> */}
            upstructstudio@gmail.com <br />
            +40 737 967 797 <br />
          </div>
        </Col>
      </Row>
    </Fragment>
  );
}
