import React, { Fragment, useContext, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { AppContext } from "../../components/AppLayout";

export default function Video(props: any) {
  const context = useContext(AppContext);
  const title = context.currentPageTitle;
  const category = context.currentPageCategory;
  useEffect(() => {
    context.setCurrentPageCategory("Rendering & Visualization");
    context.setCurrentPageTitle("VIDEO");
  }, []);

  return (
    <Fragment>
      <div className="main-content">
        <Row>
          <Col>
            <Row>
              <div
                style={{
                  marginBottom: 30,
                  marginLeft: 10,
                  fontFamily: "Roboto-Light",
                  color: "#757575",
                  fontSize: "14px",
                }}
                className={"title"}
              >
                {category}
              </div>
            </Row>
            <Row>
              <div
                style={{
                  marginLeft: 10,
                  marginBottom: 50,
                  fontFamily: "Wremena Regular",
                  fontSize: "20px",
                }}
              >
                {title}
              </div>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginLeft: "0px" }}>
          <p>
            Animația arhitecturală este un puternic instrument de marketing
            imobiliar care vă permite să vă prezentați proiectele în cel mai
            eficient mod posibil, cu caracteristici empatice și cinematice care
            vor capta atenția clienților și îi vor introduce într-o simulare 3D
            realistică.
          </p>
          <img
            className="img-fluid"
            src="/../../images/video/VIDEO-1.png"
          ></img>
        </Row>
      </div>
    </Fragment>
  );
}
