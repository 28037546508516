import React, { Fragment } from "react";
import { SocialMediaIconsReact } from "social-media-icons-react";
import footerlogo from "./../../images/studio_logo.png";
import { Row } from "react-bootstrap";

export default function Footer(props: any) {
  return (
    <Fragment>
      <Row style={{ justifyContent: "center", marginLeft: "15%" }}>
        <img src={footerlogo} style={{ marginBottom: "5px" }}></img>
      </Row>
      <div
        className="flex-row-container"
        style={{
          marginLeft: "15%",
          borderTop: "1px #ccc solid",
          justifyContent: "space-between",
          flex: 7,
        }}
      >
        <div
          className="footer-text"
          style={{
            color: "#989898",
            marginTop: 20,
            flex: 5,
            textAlign: "left",
          }}
        >
          &copy;2020 Up Struct Studio. All Rights Reserved.
        </div>
        <div style={{ marginTop: 10, flex: 5, textAlign: "right" }}>
          <SocialMediaIconsReact
            iconColor={"#989898"}
            backgroundColor={"#fff"}
            icon="facebook"
            url={"https://www.facebook.com/UpStructRomania"}
          />
          <SocialMediaIconsReact
            iconColor={"#989898"}
            backgroundColor={"#fff"}
            icon="instagram"
            url={"https://www.instagram.com/up.struct.studio/"}
          />
          <SocialMediaIconsReact
            iconColor={"#989898"}
            backgroundColor={"#fff"}
            icon="youtube-play"
            url={"https://www.youtube.com"}
          />
        </div>
      </div>
    </Fragment>
  );
}
