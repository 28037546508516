import React, { Fragment } from "react";
import logo from "./../../images/BlackLogo.png";
import { Row, Carousel } from "react-bootstrap";

import Images from "./images";

export default function Home(props: any) {
  const images = Images;
  const imgSrc = images.map((image: any) => {
    return (
      <Carousel.Item key={image.key}>
        <img
          alt="home"
          className="img-fluid"
          style={{ maxWidth: "100%", height: "auto" }}
          src={image.src}
        />
      </Carousel.Item>
    );
  });
  return (
    <Fragment>
      <Row>
        <Carousel interval={3000} controls={false}>
          {imgSrc}
        </Carousel>
      </Row>
    </Fragment>
  );
}
