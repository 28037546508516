import React, { Fragment, useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import "./index.scss";
export default function GalleryComponent(props: any) {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event: any, { photo, index }: any) => {
    setCurrentImage(event.index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const imageRenderer = useCallback(
    (x: any) => (
      <img
        alt=""
        className={"gallery-img"}
        height="auto"
        key={x.key}
        src={x.photo.src}
        {...x.photo}
        onClick={() => x.onClick(x.photo, x.index)}
      />
    ),
    []
  );

  return (
    <Fragment>
      <div style={{ width: "100%", height: "auto" }}>
        <Gallery
          height="100%"
          renderImage={imageRenderer}
          photos={props.images}
          direction={"column"}
          columns={1}
          onClick={openLightbox}
        ></Gallery>
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={props.images.map((image: any) => ({
                  ...image,
                  srcset: image.src,
                  caption: "",
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </Fragment>
  );
}
