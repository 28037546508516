import React from 'react';
import './App.css';
import AppLayout from './components/AppLayout';


function App() {
  return (
    <>
      <title>UpStruct</title>
      <AppLayout />
    </>
  );
}

export default App;
