import React, { Fragment, useEffect, useContext } from "react";
import "./index.scss";
import ProjectsComponent from "../../components/ProjectsComponent";
import { AppContext } from "../../components/AppLayout";
import { Col, Row } from "react-bootstrap";
export default function Houses(props: any) {
  const images = [
    {
      src: "/../../images/houses/1SHOUSEMOGOSOAIA/1.jpg",
      width: 1.5,
      height: 1,
      key: "1",
      project: "houses/1SHOUSEMOGOSOAIA",
      projectName: "S HOUSE - MOGOSOAIA",
      totalPics: 2,
    },
    {
      src: "/../../images/houses/2RHOUSENAVODARI/1.jpg",
      width: 1.5,
      height: 1,
      key: "2",
      project: "houses/2RHOUSENAVODARI",
      projectName: "R HOUSE - NAVODARI",
      totalPics: 1,
    },
    {
      src: "/../../images/houses/3DSHOUSECONSTANTA/1.jpg",
      width: 1.5,
      height: 1,
      key: "3",
      project: "houses/3DSHOUSECONSTANTA",
      projectName: "DS HOUSE - CONSTANTA",
      totalPics: 3,
    },
    {
      src: "/../../images/houses/4CHOUSEMOGOSOAIA/1.jpg",
      width: 1.5,
      height: 1,
      key: "4",
      project: "houses/4CHOUSEMOGOSOAIA",
      projectName: "C HOUSE - MOGOSOAIA",
      totalPics: 5,
    },
    {
      src: "/../../images/houses/5TTHOUSECONSTANTA/1.jpg",
      width: 1.5,
      height: 1,
      key: "5",
      project: "houses/5TTHOUSECONSTANTA",
      projectName: "TT HOUSE - CONSTANTA",
      totalPics: 2,
    },
    {
      src: "/../../images/houses/6P2EHOUSECONSTANTA/1.jpg",
      width: 1.5,
      height: 1,
      key: "6",
      project: "houses/6P2EHOUSECONSTANTA",
      projectName: "P2E HOUSE - CONSTANTA",
      totalPics: 1,
    },
    {
      src: "/../../images/houses/7SYVHOUSENAVODARI/1.jpg",
      width: 1.5,
      height: 1,
      key: "7",
      project: "houses/7SYVHOUSENAVODARI",
      projectName: "SYV HOUSE - NAVODARI",
      totalPics: 5,
    },
    {
      src: "/../../images/houses/8MANILVAHOUSEANDALUZIASPIAN/1.jpg",
      width: 1.5,
      height: 1,
      key: "8",
      project: "houses/8MANILVAHOUSEANDALUZIASPIAN",
      projectName: "MANILVA HOUSE - ANDALUZIA SPIAN",
      totalPics: 3,
    },
    {
      src: "/../../images/houses/10OTOPENIHOUSESBUCURESTI/1.jpg",
      width: 1.5,
      height: 1,
      key: "10",
      project: "houses/10OTOPENIHOUSESBUCURESTI",
      projectName: "OTOPENI HOUSES - BUCURESTI",
      totalPics: 4,
    },
  ];
  const context = useContext(AppContext);
  useEffect(() => {
    context.setCurrentPageCategory("Houses");
  }, []);
  return (
    <Fragment>
      <div className="main-content">
        <Col>
          <Row>
            <div
              style={{
                marginBottom: 30,
                marginLeft: 10,
                fontFamily: "Roboto-Light",
                color: "#757575",
                fontSize: "14px",
              }}
              className={"title"}
            >
              Architecture
            </div>
          </Row>
          <Row>
            <div
              style={{
                marginLeft: 10,
                marginBottom: 50,
                fontFamily: "Wremena Regular",
                fontSize: "20px",
              }}
            >
              HOUSES
            </div>
          </Row>
        </Col>
        <ProjectsComponent images={images} />
      </div>
    </Fragment>
  );
}
