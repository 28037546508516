import React, { Fragment, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../components/AppLayout";
import GalleryComponent from "../../components/GalleryComponent";
import { Row, Col } from "react-bootstrap";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ProjectsPage(props: any) {
  const context = useContext(AppContext);

  useEffect(() => {
    if (context.currentPageTitle) {
      localStorage.setItem("currentPageCategory", context.currentPageCategory);
      localStorage.setItem("currentPageTitle", context.currentPageTitle);
      localStorage.setItem(
        "currentPageImgCount",
        context.currentPageImgCount.toString()
      );
    } else {
      context.setCurrentPageImgCount(localStorage["currentPageImgCount"]);
      context.setCurrentPageTitle(localStorage["currentPageTitle"]);
      context.setCurrentPageCategory(localStorage["currentPageCategory"]);
    }
  }, [context]);

  let query = useQuery();
  const projName = query.get("name");
  const imagesPath = "/../../images/" + projName;
  const category = context.currentPageCategory;
  const title = context.currentPageTitle;
  var images: [{}] = [{}];
  images.pop();
  for (let i = 0; i < context.currentPageImgCount; i++) {
    images.push({
      src: imagesPath + "/" + (i + 1) + ".jpg",
      width: 1.5,
      height: 1,
      key: i,
      index: i,
    });
  }

  return (
    <Fragment>
      <Row className="main-content">
        <Col>
          <Row>
            <div
              style={{
                marginBottom: 30,
                marginLeft: 10,
                fontFamily: "Roboto-Light",
                color: "#757575",
                fontSize: "14px",
              }}
              className={"title"}
            >
              {category}
            </div>
          </Row>
          <Row>
            <div
              style={{
                marginLeft: 10,
                marginBottom: 50,
                fontFamily: "Wremena Regular",
                fontSize: "20px",
              }}
            >
              {title}
            </div>
          </Row>
        </Col>
        <GalleryComponent images={images} />
      </Row>
    </Fragment>
  );
}
