import React, { Fragment, useCallback, useContext } from "react";
import Gallery from "react-photo-gallery";
import { AppContext } from "../AppLayout";
import "./index.scss";
import { Link } from "react-router-dom";
export default function ProjectsComponent(props: any) {
  const context = useContext(AppContext);
  const imageRenderer = useCallback(
    (x: any) => (
      <Link
        style={{
          margin: 0,
          width: `${x.photo.width}px`,
          height: `${x.photo.height}px`,
        }}
        key={x.key}
        to={"/projects?name=" + x.photo.project}
      >
        <div
          style={{
            margin: 0,
            top: x.top,
            left: x.left,
            width: `${x.photo.width}px`,
            height: `${x.photo.height}px`,
          }}
          className="overlay-container"
          onClick={() => {
            context.setCurrentPageTitle(x.photo.projectName);
            context.setCurrentPageImgCount(x.photo.totalPics);
          }}
        >
          <img alt="" className={"image"} src={x.photo.src} {...x.photo} />
          <div
            className="overlay"
            style={{
              width: `${x.photo.width - 20}px`,
              height: `${x.photo.height - 20}px`,
            }}
          >
            <div className="text">{x.photo.projectName}</div>
          </div>
        </div>
      </Link>
    ),
    [context]
  );

  return (
    <Fragment>
      <Gallery
        renderImage={imageRenderer}
        photos={props.images}
        direction={"column"}
        columns={2}
      ></Gallery>
    </Fragment>
  );
}
