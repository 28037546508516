import React, { Fragment, useContext, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Pannellum } from "pannellum-react";
import { AppContext } from "../../components/AppLayout";

export default function VrComponent(props: any) {
  const context = useContext(AppContext);
  const title = context.currentPageTitle;
  const category = context.currentPageCategory;
  useEffect(() => {
    context.setCurrentPageCategory("Rendering & Visualization");
    context.setCurrentPageTitle("VR / 360");
  }, []);
  return (
    <Fragment>
      <div className="main-content">
        <Row>
          <Col>
            <Row>
              <div
                style={{
                  marginBottom: 30,
                  marginLeft: 10,
                  fontFamily: "Roboto-Light",
                  color: "#757575",
                  fontSize: "14px",
                }}
                className={"title"}
              >
                {category}
              </div>
            </Row>
            <Row>
              <div
                style={{
                  marginLeft: 10,
                  marginBottom: 50,
                  fontFamily: "Wremena Regular",
                  fontSize: "20px",
                }}
              >
                {title}
              </div>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px", marginLeft: "0px" }}>
          <p>
            Promovați-vă proiectele în afara planului. Realitatea virtuală poate
            fi un mod inventiv de a promova proiectele fără cheltuiala unui plan
            de marketing. Randările 360 și experiențele VR înseamnă că
            potențialii cumpărători își pot explora spațiile noi folosind căști
            VR sau chiar un browser web.
          </p>
          <img className="img-fluid" src="/../../images/vr/VR-360-1.png"></img>
        </Row>
        <Row style={{ marginBottom: "20px", marginLeft: "0px" }}>
          <Pannellum
            image="/../../images/vr/360-1.jpg"
            autoLoad={true}
            pitch={10}
            yaw={180}
            hfov={110}
          ></Pannellum>
        </Row>
        <Row style={{ marginLeft: "0px" }}>
          <Pannellum
            image="/../../images/vr/360-2.jpg"
            autoLoad={true}
            pitch={10}
            yaw={180}
            hfov={110}
          ></Pannellum>
        </Row>
      </div>
    </Fragment>
  );
}
